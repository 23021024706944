<template>
  <div>
    <br/><br/>
    <h1 class="main-title">Таблица по таскам</h1>
    <!-- <h1 class="main-title">Сводка {{tableTitle}}</h1> -->
    <div class="p-left menu-line">
      <div class="menu-line__item">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="menu-line">
              <v-text-field
                v-model="dateRangeText"
                label="Выберите даты"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                hide-details
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
              <v-btn
                v-if="dateRangeText"
                icon
                color="blue"
                @click="clearDate"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </template>
          <v-date-picker
            v-model="filters.dates"
            range
            locale="ru"
            first-day-of-week="1"
            scrollable
            clearable
            no-title
            :max="new Date().toISOString().substr(0, 10)"
          >
          </v-date-picker>
        </v-menu>
      </div>

			<v-autocomplete
				class="narrow menu-line__item"
				v-model="filters.project_id"
				:items="projects"
				outlined
				dense
				hide-details
				light
				chips
				small-chips
				deletable-chips
				:hide-no-data="true"
				clearable
				item-text="project_id"
				item-value="project_id"
				label="Фильтр по project_id"
				:filter="projectFilterFunction"
			>
				<template v-slot:item="{ item }">
					<span><sup>{{item.project_id}}</sup> {{item.name ? item.name : '-'}}</span>
				</template>
			</v-autocomplete>

      <v-autocomplete
				v-if="filters.project_id"
				class="narrow menu-line__item"
				v-model="filters.url_id"
				:items="urls"
				outlined
				dense
				hide-details
				light
				chips
				small-chips
				deletable-chips
				clearable
				:hide-no-data="true"
				item-text="url_id"
				item-value="url_id"
				label="Фильтр по url_id"
				:filter="urlFilterFunction"
			>
				<template v-slot:item="{ item }">
					<span><sup>{{item.url_id}}</sup> {{item.url ? item.url : '-'}}</span>
				</template>
			</v-autocomplete>

      <v-btn
        class="menu-line__item"
        v-if="urlFilters.status"
        @click="removeStatus"
      >
        Сброс фильтра по статусу
      </v-btn>
			<v-btn
				class="menu-line__item"
				v-if="!!selectedIds.length"
				@click="restart"
			>
				Перезапустить
			</v-btn>
    </div>
		<br/>
		<div class="menu-line">
			<div class="p-left"><strong>Всего:</strong> {{total}}</div>
		</div>
		<br/>

    <v-data-table
			class="table-narrow"
      :headers="pivotHeaders"
      :items="tableStats"
			:loading="loading.statistic"
      :items-per-page="-1"
			hide-default-footer
      dense
    >
      <template v-slot:item.count="{ item }">
        <component
          class="status-link"
          :is="item.isError ? 'a' : 'span'"
					@click="filterTasksByStatus(item)"
        >
          {{item.count}}
        </component>
      </template>
      <template v-slot:item.percent="{ item }">
        <span v-if="item.percent">{{item.percent.toLocaleString({maximumFractionDigits: 2})}}%</span>
      </template>
    </v-data-table>
		<br/>
    <v-data-table
      :headers="headers"
      :items="tasks"
			:loading="loading.main"
			:server-items-length="total"
      dense
      show-select
      v-model="selectedRows"
			@update:options="updPagination"
			:footer-props="{
				'items-per-page-options': itemsPerPage,
				'items-per-page-text': 'Строк на странице:'
			}"
    >
      <template v-slot:item.error_id="{ item }">
        <span>{{item.error_id ? item.error_id : '-'}}</span>
      </template>
      <template v-slot:item.url="{ item }">
        <span>{{item.url ? item.url : '-'}}</span>
      </template>
      <template v-slot:item.code="{ item }">
				<v-tooltip bottom max-width="300px">
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">{{item.code ? item.code : '-'}}</span>
					</template>
					<span>{{item.text ? item.text : 'Текст ошибки не найден.'}}</span>
				</v-tooltip>
      </template>
			<template #footer.prepend>
				<v-spacer/>
			</template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
	data () {
		return {
			loading: {
				main: true,
				statistic: true
			},
			headers: [
				{
					text: 'Task ID',
					value: 'id',
					sortable: false
				},
				{
					text: 'Project ID',
					value: 'project_id',
					sortable: false
				},
				{
					text: 'URL ID',
					value: 'url_id',
					sortable: false
				},
				{
					text: 'URL',
					value: 'url',
					sortable: false
				},
				{
					text: 'Updated at',
					value: 'updated_at',
					sortable: false
				},
				{
					text: 'Type',
					value: 'type',
					sortable: false
				},
				{
					text: 'Status',
					value: 'status',
					sortable: false
				},
				{
					text: 'Error ID',
					value: 'error_id',
					sortable: false
				},
				{
					text: 'Code',
					value: 'code',
					sortable: false
				}
			],
			pivotHeaders: [
				{
					text: 'Статус',
					value: 'name',
					sortable: false
				},
				{
					text: 'Всего',
					value: 'count',
					sortable: false
				},
				{
					text: 'Процент',
					value: 'percent',
					sortable: false
				}
			],
			selectedRows: [],
			filters: {
				dates: undefined,
				project_id: undefined,
				url_id: undefined,
				page: 1,
				itemsPerPage: 50,
				status: undefined
			},
			menu: false,
			itemsPerPage: [50, 100, 500, 1000]
		}
	},
	computed: {
		...mapState({
			tasks: state => state.gsc.tasks,
			total: state => state.gsc.total,
			projects: state => state.gsc.projects,
			urls: state => state.gsc.urls
		}),
		...mapGetters({
			tableStats: 'gsc/tableStats'
		}),
		selectedIds () {
			return this.selectedRows.map((el) => {
				return {
					id: el.id
				}
			})
		},
		statusesCount () {
			if (this.tasks.length) {
				return this.statuses.map((status) => {
					const items = this.tasks.filter((task) => +task.status === +status.status).length
					const percent = (items / +this.tasks.length) * 100
					return {
						...status,
						percent: isFinite(percent) ? `${percent}%` : '-',
						count: items
					}
				})
			} else {
				return []
			}
		},

		today () {
			let today = new Date()
			const dd = String(today.getDate()).padStart(2, '0')
			const mm = String(today.getMonth() + 1).padStart(2, '0')
			const yyyy = today.getFullYear()

			today = dd + '.' + mm + '.' + yyyy
			return today
		},

		tableTitle () {
			if (Array.isArray(this.filters.dates) && this.filters.dates.length === 2) {
				return this.dateRangeText
			}
			return this.today
		},

		urlFilters () {
			const query = { ...this.$route.query }

			return query
		},

		dateRangeText () {
			return this.sortDates(this.filters.dates).map((el) => this.formatDate(el)).join(' - ')
		},
		urlIDs () {
			if (this.urlFilters.project_id) {
				const filtered = this.tasks.filter((el) => +this.urlFilters.project_id === el.project_id)
				return Array.from(new Set(filtered.map((el) => el.url_id)))
			}

			return Array.from(new Set(this.tasks.map((el) => el.url_id)))
		}
	},
	methods: {
		...mapActions({
			getTasks: 'gsc/getTasks',
			getStats: 'gsc/getStats',
			getProjects: 'gsc/getProjects',
			getUrls: 'gsc/getUrls',
			restartTasks: 'gsc/restartTasks'
		}),
		/* Обновление URL */
		async updateUrl (query) {
			await this.$router.replace({
				query: {
					...this.$route.query,
					...query
				}
			}).catch((_) => {})
		},

		projectFilterFunction (item, queryText, itemText) {
			return item.name.toLocaleLowerCase().indexOf(queryText.trim().toLocaleLowerCase()) > -1 || item.project_id.toString().indexOf(queryText.trim().toString()) > -1
		},
		urlFilterFunction (item, queryText, itemText) {
			return item.url.toLocaleLowerCase().indexOf(queryText.trim().toLocaleLowerCase()) > -1 || item.url_id.toString().indexOf(queryText.trim().toString()) > -1
		},

		async initData () {
			this.$store.commit('gsc/clearTasks')
			this.$store.commit('gsc/clearStats')
			const dateInput = {}

			if (this.filters.dates && Array.isArray(this.filters.dates) && this.filters.dates.length === 2) {
				const dates = this.sortDates(this.filters.dates)
				dateInput.date_from = dates[0]
				dateInput.date_to = dates[1]
			}

			try {
				this.loading.main = true

				await this.getTasks({
					...dateInput,
					offset: this.filters.page === 1 ? 0 : this.filters.itemsPerPage * (this.filters.page - 1),
					limit: this.filters.itemsPerPage,
					project_id: this.urlFilters.project_id,
					url_id: this.urlFilters.url_id,
					status: this.urlFilters.status
				})
			} catch (error) {
				console.log(error)
			} finally {
				this.loading.main = false
			}
			try {
				this.loading.statistic = true

				await this.getStats({
					...dateInput,
					project_id: this.urlFilters.project_id,
					url_id: this.urlFilters.url_id
				})
			} catch (error) {
				console.log(error)
			} finally {
				this.loading.statistic = false
			}
		},

		filterTasksByStatus (task) {
			this.filters.status = task.status
			this.updateUrl({
				status: task.status
			})
		},
		removeStatus () {
			this.filters.status = undefined
			this.updateUrl({
				status: undefined
			})
		},
		clearDate () {
			this.filters.dates = []
		},
		setFilters () {
			if (this.urlFilters.project_id) {
				this.filters.project_id = +this.urlFilters.project_id
			}
			if (this.urlFilters.status) {
				this.filters.status = +this.urlFilters.status
			}
			if (this.urlFilters.url_id) {
				this.filters.url_id = +this.urlFilters.url_id
			}
			if (this.urlFilters.date_from && this.urlFilters.date_to) {
				this.filters.dates = [this.urlFilters.date_from, this.urlFilters.date_to]
			} else {
				this.filters.dates = []
			}
		},

		formatDate (date) {
			return date.split('-').reverse().join('.')
		},

		sortDates (array) {
			const copy = [...array]
			return copy.sort(function (a, b) {
				return new Date(a) - new Date(b)
			})
		},

		async restart () {
			await this.restartTasks(this.selectedIds)
		},

		updPagination (pagination) {
			this.filters.page = this.filters.itemsPerPage !== pagination.page ? pagination.page : 1
			this.filters.itemsPerPage = pagination.itemsPerPage
		},

		checkEqualArrays (array1, array2) {
			return Array.isArray(array1) &&
							Array.isArray(array2) &&
							(array1.length == array2.length) &&
							array1.every(function (element, index) {
								return element === array2[index]
							})
		}
	},

	async created () {
		await this.setFilters()
		await this.getProjects()
	},

	watch: {
		'filters.dates': {
			handler (val, oldVal) {
				if (!this.checkEqualArrays(val, oldVal)) {
					this.filters.page = 1
				}
			}
		},
		'filters.project_id': {
			async handler (val, oldVal) {
				if (val !== oldVal && val) {
					await this.getUrls(val)
				}
				if (!val) {
					this.filters.url_id = undefined
				}
				this.filters.page = 1
			},
			immediate: true
		},
		'filters.url_id': {
			handler () {
				this.filters.page = 1
			}
		},
		filters: {
			async handler (val) {
				const filtersDates = val.dates
				const filtersProjectId = val.project_id
				const filtersUrlId = val.url_id
				const status = val.status

				const dates = this.sortDates(filtersDates)

				this.updateUrl({
					date_from: dates && dates.length === 2 && dates[0] ? dates[0] : undefined,
					date_to: dates && dates.length === 2 && dates[1] ? dates[1] : undefined,
					project_id: filtersProjectId !== null ? filtersProjectId : undefined,
					url_id: filtersUrlId !== null ? filtersUrlId : undefined,
					status: status
				})

				if (dates.length === 2 || dates.length === 0) {
					this.menu = false
					await this.initData()
				}
			},
			deep: true
		}
	}
}
</script>

<style lang="scss" scoped>
	.main-title {
		margin-bottom: 20px;
	}
  a.status-link {
    text-decoration: underline;
  }
  .menu-line {
    display: flex;
    align-items: center;

    .menu-line__item:not(:last-child) {
      display: block;
      margin-right: 10px;
    }
  }
  .narrow {
    max-width: 200px;
  }

	.table-narrow {
		max-width: 700px;
	}

  .wide {
    max-width: 400px;
  }

	.p-left {
		padding-left: 15px;
	}

	.v-tooltip__content {
		word-wrap: break-word;
	}
</style>
